import * as React from "react"
import Layout from '../components/layout';

export default () => {
  return (
    <Layout>
      <h1>Fatou</h1>
      <p>Lorem Ipsum</p>
      <ul>
        <li>
          <span>COLLECTION:</span> Black Hairs Matter
        </li>
        <li>
          DATE CREATED: xx/xx/xx
        </li>
        <li>
          TOOLS USED:
        </li>
        <li>
          ORIGINAL RESOLUTION: 
        </li>
        <li>
          FILE SIZE: xx bytes
        </li>
      </ul>
    </Layout>
  )
}
